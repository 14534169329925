/// <reference path="./custom-window.d.ts" />
import { Modal } from 'bootstrap';

window.PLModalInit = function () {
}

window.PLModalInstall = function (id, custom, separator) {
  console.log('PLModal install, custom=' + custom);
  let modalId = id + separator + 'modal';
  let formId = id + separator + 'form';
  let submitId = id + separator + 'submit';
  let customId = id + separator + 'custom';

  const submit = document.getElementById(submitId);

  if (submit && !custom) {
    submit.onclick = function () {
      const form = document.getElementById(formId) as HTMLFormElement;
      const isValid = form.checkValidity();
      if (isValid) {
        window.htmx.trigger(`#${customId}`, 'click');
      }
    }
  }

  const element = document.getElementById(modalId) as HTMLElement;
  const modal = Modal.getOrCreateInstance(element, { backdrop: 'static', keyboard: false });
  if (modal) {
    element.addEventListener('hide.bs.modal', (event: Event) => {
      console.log('PlModal is hiding');
    });

    modal.show();
  }
}

window.PLModalClose = function (id, trigger, separator) {
  console.log('PLModal close, trigger=' + trigger);
  let modalId = id + separator + 'modal';

  const element = document.getElementById(modalId) as HTMLElement;
  const modal = Modal.getInstance(element);
  if (modal) {
    modal.hide();
    modal.dispose();
    element.remove(); // Remove traces of the dialog
  }
  if (trigger && trigger !== '') {
    window.htmx.trigger(trigger, 'refresh')
  }
}

console.log('PLModal has been installed');


window.PLModalResizeContent = function (containerId, rootId) {
  console.log("PLModalResizeContent", containerId)
  const content = document.querySelector('#' + containerId) as HTMLElement;
  const root = document.querySelector('#' + rootId) as HTMLElement;
  const parentModal = content.closest('.modal.show') as HTMLElement;
  if (content && parentModal) {
    content.style.transform = "scale(0.7) translate(0px, 0px)";
    content.style.transformOrigin = "top center";
    content.style.marginTop = "5rem";
    root.style.transform = "scale(0.5) translate(-50%, -50%)";
  }
}