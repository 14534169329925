/// <reference path="./custom-window.d.ts" />
import Tagify from "@yaireo/tagify";

interface TagifyTag {
  value: string | undefined,
  isValid: boolean
}

window.PLListGroupInstall = function (id, optionsId, textId, addId, plainItem, booleanItem, listGroup, multiEmail, sep) {
  const group = document.getElementById(id + sep + 'group') as HTMLElement;
  const text = document.getElementById(textId) as HTMLInputElement;
  const options = document.getElementById(optionsId) as HTMLSelectElement;
  const add = document.getElementById(addId) as HTMLButtonElement;
  const booleanClone = document.getElementById(id + sep + 'boolean-clone' + sep + listGroup);
  const plainItemClone = document.getElementById(id + sep + 'plain-item-clone' + sep + listGroup);
  const numberItemClone = document.getElementById(id + sep + 'number-item-clone' + sep + listGroup);
  let tagify: Tagify | undefined
  let allSavedTags: TagifyTag[] = [];
  if (multiEmail === "true") {
    tagify = new Tagify(text, {
      delimiters: ";",
      placeholder: "Enter emails separated by ;",
      pattern: /^[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      keepInvalidTags: true,
      createInvalidTags: true,
      skipInvalid: false,
      dropdown: {
        enabled: 0,
      },
      texts: {
        duplicate: "E-posten finns redan",
        pattern: "Ogiltig e-post",
      }
    });

    tagify.on("add", (e) => {
      const addedTag = e.detail.data;
      allSavedTags.push({
        value: addedTag?.value,
        isValid: addedTag?.__isValid,
      });
    });

    tagify.on("invalid", (e) => {
      allSavedTags.push({
        value: e.detail.data.value,
        isValid: false,
      });
    });
    function removeErrorSpan() {
      const existingErrorSpan = group.querySelector(".error-message");
      if (existingErrorSpan) {
        text.setCustomValidity("");
        text.reportValidity();
        existingErrorSpan.remove();
      }
    }
    tagify.on("remove", (e) => {
      if (e.detail.data) {
        allSavedTags = allSavedTags.filter((tag) => tag.value !== e.detail?.data?.value)
      } else {
        const tagData = e.detail.tag as any
        allSavedTags = allSavedTags.filter((tag) => tag.value !== tagData.__tagifyTagData.value)
      }
      removeErrorSpan()
    });
  }

  function removeBtn(element: HTMLElement, value: string, optionId: string) {
    let closeBtn = element.getElementsByClassName('btn')[0];
    closeBtn.setAttribute("onClick", `PLListGroupRemoveRow('${value}', '${optionId}')`)
  }


  add.addEventListener('click', function () {
    let valueAndType;
    let input;
    let selectValue;
    let selectValueTitle;

    if (multiEmail === "true" && tagify) {
      const validTags = allSavedTags.filter((tag) => tag.isValid);
      const invalidTags = allSavedTags.filter((tag) => !tag.isValid);

      if (invalidTags.length > 0) {
        text.setCustomValidity("Ogiltig e-postadress")
        text.reportValidity()
        const errorSpan = document.createElement("span");
        errorSpan.className = "error-message";
        errorSpan.textContent = "Ogiltig e-postadress";

        const inputGroup = group.querySelectorAll("input-group")[1];
        if (inputGroup) {
          inputGroup.appendChild(errorSpan);
        } else {
          group.appendChild(errorSpan);
        }
        return;
      }

      validTags.forEach((tag) => {
        valueAndType = [tag.value, text.id.split(sep)[1]];
        const { value, type, trailing } = filtered(valueAndType);
        let element = createElement(value, type, trailing, value, id, listGroup, value);
        group.appendChild(element);
      });

      tagify.removeTags((tagify.value.filter((tag) => tag.__isValid).map((tag) => tag.value) as unknown as HTMLElement));
      allSavedTags = [];
      return
    }

    else if (text != null && text.value !== "") {
      valueAndType = [text.value, text.id.split(sep)[1]];
      input = text;
    } else {
      selectValue = options.value;
      selectValueTitle = options.options[options.selectedIndex].innerHTML;
      let optionSplit = selectValue.split(sep);
      valueAndType = [optionSplit[1], optionSplit[2]];
      input = options;
    }

    const { value, type, trailing } = filtered(valueAndType);
    if (selectValue === undefined) {
      selectValue = id + sep + value + sep + type;
      selectValueTitle = text.value;
    }

    const currentValues = window.PLListGroupUpdateOptions(optionsId).map((v) => v.toLowerCase());

    if (value !== undefined && !currentValues.includes(selectValue.toLowerCase())) {
      if (value && input.checkValidity()) {
        let element = createElement(value, type, trailing, selectValueTitle ? selectValueTitle : "", id, listGroup, selectValue);
        group.appendChild(element);

        window.PLListGroupUpdateOptions(optionsId);
        if (text) text.value = "";
        if (options) options.selectedIndex = 0;
      }
    } else if (text) {
      text.style.borderColor = "red";
      text.value = "";
      setTimeout(() => {
        text.removeAttribute("style");
      }, 3000);
    } else {
      options.style.borderColor = "red";
      setTimeout(() => {
        options.removeAttribute("style");
      }, 3000);
    }
  });

  function createElement(value: string, type: string, trailing: string | null, selectValueTitle: string, id: string, listGroup: string, selectValue: string) {
    let element: HTMLInputElement;
    if (type === plainItem) {
      element = plainItemClone!!.cloneNode(true) as HTMLInputElement;
      let inputHidden = element.getElementsByClassName('pl-list-group-input')[0];
      inputHidden.setAttribute("name", id + sep + value);
    } else if (type === booleanItem) {
      element = booleanClone!!.cloneNode(true) as HTMLInputElement;
    } else {
      element = numberItemClone!!.cloneNode(true) as HTMLInputElement;
      let trailingDiv = element.getElementsByClassName('input-group-text')[0];
      if (trailing !== "" && trailing && trailingDiv instanceof HTMLElement) {
        trailingDiv.innerText = trailing;
      } else {
        trailingDiv.remove();
      }

      const numberInputForTrailing = element.getElementsByClassName('form-control')[0] as HTMLInputElement;
      numberInputForTrailing.setAttribute("name", id + sep + value);
      numberInputForTrailing.setAttribute("id", id + sep + value);
      numberInputForTrailing.addEventListener("change", () => {
        numberInputForTrailing.setAttribute("value", numberInputForTrailing.value);
      });
      numberInputForTrailing.setAttribute("aria-describedby", id + sep + value + '.hint');
    }

    // Give remove button the right target
    removeBtn(element, id + sep + value + sep + listGroup, optionsId);

    // Give the clone values
    element.setAttribute("id", id + sep + value + sep + listGroup);
    element.setAttribute("style", "");
    element.setAttribute("data-value", selectValue);

    let formCheckInput = element.querySelectorAll('.form-check-input');
    formCheckInput.forEach((p) => {
      p.setAttribute("name", id + sep + value);
    });

    let valueHidden = element.getElementsByClassName('pl-list-group-value')[0];
    valueHidden.textContent = selectValueTitle;

    return element;
  }

  window.PLListGroupRemoveRow = function (id, optionsId) {
    const element = document.getElementById(id) as HTMLElement;
    const parent = element.parentNode as HTMLElement;
    parent.removeChild(element);
    window.PLListGroupUpdateOptions(optionsId);
  };

  window.PLListGroupUpdateOptions = function (id): string[] {
    let currentValues = [] as string[];
    const currentValuesElements = document.querySelectorAll('.list-group-item');
    currentValuesElements.forEach((p) => {
      const el = p as HTMLElement;

      if (el.dataset.value) {
        currentValues.push(el.dataset.value);
      }
    });
    const selectsOptions = document.getElementById(id) as HTMLSelectElement;
    if (selectsOptions != null) {
      for (let i = 0; i < selectsOptions.options.length; i++) {
        let option = selectsOptions.options[i];
        if (currentValues.includes(option.value)) {
          option.style.display = 'none';
          option.style.visibility = 'hidden';
          option.disabled = true;
        } else {
          option.style.display = '';
          option.disabled = false;
          option.style.visibility = 'visible';
        }
      }
    }
    return currentValues;
  };

  function filtered(valueAndType: ((string | undefined)[])): { value: string, type: string, trailing: string | null } {
    if (valueAndType[1]?.includes("|")) {
      let typeAndTrailing = valueAndType[1].split("|");
      return { value: valueAndType[0] ? valueAndType[0] : "", type: typeAndTrailing[0], trailing: typeAndTrailing[1] };
    } else {
      return { value: valueAndType[0] ? valueAndType[0] : "", type: valueAndType[1] ? valueAndType[1] : "", trailing: null };
    }
  }
  window.PLListGroupUpdateOptions(optionsId);
};

console.log('PLListGroup has been installed');
