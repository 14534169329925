
window.PLMonitoringInstall = function (separator) {
	document.addEventListener('DOMContentLoaded', function (evt) {
		let sessionStart = Date.now(); // Record session start time
		let lastActivity = Date.now(); // Record the last activity time
		let inactivityThreshold = 30000; // 30 seconds of inactivity
		let visitThreshold = 10000; // 10 seconds for a valid page visit
		let inactivityTimer: string | number | NodeJS.Timeout | undefined;
		let sessionActive = true;
		let pageVisitRegistered = false;
		let maxScrollPercentage = 0; // Track the maximum scroll depth

		const registerPageVisit = () => {
			if (!pageVisitRegistered) {
				sendEvent('visit', undefined);
				pageVisitRegistered = true;
			}
		};

		const registerSessionDuration = () => {
			if (sessionActive) {
				const sessionEnd = Date.now();
				const sessionDuration = (sessionEnd - sessionStart) / 1000; // TO sek
				sendEvent('duration', sessionDuration)
				sendEvent('coverage', maxScrollPercentage)
				sessionActive = false; // Mark session as inactive
			}
		};

		const resetInactivityTimer = () => {
			if (!sessionActive) return;

			clearTimeout(inactivityTimer);
			lastActivity = Date.now();

			// Restart the inactivity timer
			inactivityTimer = setTimeout(() => {
				registerSessionDuration(); // End session due to inactivity
			}, inactivityThreshold);
		};

		const calculateScrollPercentage = () => {
			const scrollTop = window.scrollY || document.documentElement.scrollTop;
			const windowHeight = window.innerHeight || document.documentElement.clientHeight;
			const documentHeight = document.documentElement.scrollHeight;

			// Calculate the current scroll percentage
			const currentScrollPercentage = Math.min(
				(scrollTop + windowHeight) / documentHeight * 100,
				100
			);

			// Update the maximum scroll percentage
			maxScrollPercentage = Math.max(maxScrollPercentage, currentScrollPercentage);
		};

		// Start a timer to register a valid page visit
		setTimeout(registerPageVisit, visitThreshold);

		// Monitor user activity
		["mousemove", "scroll", "keydown", "click"].forEach(eventType => {
			document.addEventListener(eventType, resetInactivityTimer);
		});

		// Monitor scrolling to calculate scroll percentage
		document.addEventListener("scroll", calculateScrollPercentage);

		// Detect tab switches or page close
		document.addEventListener("visibilitychange", () => {
			if (document.visibilityState === "hidden") {
				registerSessionDuration(); // End session when the tab is hidden
			}
		});

		window.addEventListener("beforeunload", () => {
			registerSessionDuration(); // End session when the page is closed
		});

		// Start the inactivity timer
		resetInactivityTimer();
	});
}

async function sendEvent(type: 'visit' | 'duration' | 'coverage', amount?: number) {
	const formData = new FormData();
	formData.append('type', type);
	formData.append('amount', amount ? amount.toString() : '');
	await fetch('/pe', {
		method: 'POST',
		body: formData
	});
}

console.log('PLMonitoring has been installed');
